/* eslint-disable global-require */
import { applyMiddleware, createStore, Middleware, StoreEnhancer } from 'redux';
import createSagaMiddleware from 'redux-saga';
import getConfig from 'next/config';
// import { createWrapper } from 'next-redux-wrapper';
// import logger from 'redux-logger';
import * as Sentry from '@sentry/nextjs';

import rootReducer from './reducer';
import rootSaga from './saga';

const { publicRuntimeConfig } = getConfig();
const { IS_DEV } = publicRuntimeConfig;

const bindMiddleware = (middleware: Middleware[]): StoreEnhancer => {
  if (IS_DEV) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const { composeWithDevTools } = require('redux-devtools-extension');
    // middleware.push(logger);
    return composeWithDevTools(applyMiddleware(...middleware));
  }
  return applyMiddleware(...middleware);
};

export default function configureStore(initialState = {}): any {
  const sagaMiddleware = createSagaMiddleware({
    onError: (error) => {
      Sentry.captureException({ isSagaError: true, error });
    },
  });

  const store: any = createStore(
    rootReducer,
    initialState as any,
    bindMiddleware([sagaMiddleware])
  );

  store.sagaTask = sagaMiddleware.run(rootSaga);

  return store;
}
